import React from "react";

import NotFoundComponent from "@webdex/Pages/NotFound";

const NotFound = () => {
  return (
    <NotFoundComponent/>
  );
};

export default NotFound;
